import React, { useEffect, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';

function PageNavigation(props) {
    let pageNumbersGroups = [];
    let pageNumbers = [];

    const [selectedPage, setSelectedPage] = useState(props.selectedPage ? props.selectedPage : 0);
    const [currentGroup, setCurrentGroup] = useState(0);

    const [startItem, setStartItem] = useState(0);
    const [endItem, setEndItem] = useState(0);

    useEffect(() => {
        console.log('---------------------------')
        console.log('selectedPage', props.selectedPage)
        console.log('props.itemsPerPage', props.itemsPerPage)
        console.log('props.totalPageItems', props.totalPageItems)
        const _startItem = props.totalPageItems > 0 ? (selectedPage * props.itemsPerPage) + 1 : 0;
        const _endItem = props.totalPageItems > 0 ? Math.min((props.selectedPage + 1) * props.itemsPerPage, props.totalPageItems) : 0;
        console.log('_startItem',_startItem)
        console.log('_startItem',_endItem)
        console.log('-----------*****----------------')


        setStartItem(_startItem);
        setEndItem(_endItem);
    }, [selectedPage, props.itemsPerPage, props.totalPageItems])

    useEffect(() => {
        setSelectedPage(props.selectedPage);
        if (props.selectedPage === 0) {
            setCurrentGroup(0);
        }
    }, [props.selectedPage]);

    let pageNumberCount = 0;
    for (let i = 0; i < props.totalPages; i++) {
        pageNumberCount++;
        pageNumbers.push(
            <li key={nanoid()}><a href="#" className={`${selectedPage === i ? "active" : ""}`} onClick={() => props.onPageClicked(i)}>{i + 1}</a></li>
        );
        if (pageNumberCount === props.totalVisiblePageNumbers || i === props.totalPages - 1) {
            pageNumbersGroups.push(
                <>
                    {pageNumbers}
                </>
            );
            pageNumbers = [];
            pageNumberCount = 0;
        }
    }

    const onPreviousGroupClicked = () => {
        let prevPage = selectedPage - 1;
        if (prevPage < 0) {
            return;
        }
        else if (prevPage !== 0 && (prevPage + 1) % props.totalVisiblePageNumbers === 0) {
            setCurrentGroup(currentGroup - 1);
        }
        props.onPageClicked(prevPage);
    }

    const onNextGroupClicked = () => {
        let nextPage = selectedPage + 1;
        if (nextPage > props.totalPages - 1) {
            return;
        }
        else if (nextPage % props.totalVisiblePageNumbers === 0) {
            let nextGroup = currentGroup + 1;
            setCurrentGroup(nextGroup);
        }
        props.onPageClicked(nextPage);
    }

    return (
        <tr>
            <td colSpan={props.numberOfColumns}>
                <div className="row p-2 pt-3 pb-3 pagination">
                    <div className="d-flex justify-content-between">
                        <div className="c-table-result">{`Showing ${startItem} to ${endItem} of ${props.totalPageItems} entries`}</div>
                        <div className="d-flex" >
                            <ul className="c-pagination">
                                <li><a href="#" onClick={() => onPreviousGroupClicked()}><i className="fa-solid fa-angle-left" /></a></li>
                                {pageNumbersGroups[currentGroup]}
                                <li><a href="#" onClick={() => onNextGroupClicked()}><i className="fa-solid fa-angle-right" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
}

export default PageNavigation;