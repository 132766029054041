import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import SearchBar from '../../../component/Controls/searchBars/searchbar';
import { getContacts, selectContacts, selectCurrentPage, selectStatus, selectTotalItems, updateCurrentPage, updateIsFiltered, updateStatus } from '../reducerSlices/contactsSlice';
import { fetchStatus } from '../../../api/client';
import Collection from '../../../component/Controls/paginators/collection';
import PageContainer from '../../../component/Controls/paginators/paginator/pageContainer';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { TextRow } from 'react-placeholder/lib/placeholders';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';


function Contacts() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isFiltered, setIsFiltered] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [contactRecords, setContactRecords] = useState(new Collection());
    const [dummyCards, setDummyCards] = useState([]);
    const [whereClauseFilter, setWhereClauseFilter] = useState({ searchTerm: "", followups: "" });

    const totalPageItems = useSelector(selectTotalItems);
    const _contacts = useSelector(selectContacts);
    const currentPage = useSelector(selectCurrentPage);
    const contactsRequestStatus = useSelector(selectStatus);


    useEffect(() => {
        dispatch(updateLayout({
            page: 3,
            title: "Contacts"
        }));
    }, []);

    const getBadgeClass = (followup) => {
        switch (followup) {
            case "FollowUps: Enrich":
                return "badge-enrich";
            case "FollowUps: 1-1 Sales Approach":
                return "badge-sales";
            case "FollowUps: Nurture":
                return "badge-nurture";
            case "FollowUps: Change Communication Channel":
                return "badge-change";
            case "Cold Lead: Sequence":
                return "badge-sequence";
            case "Cold Lead: Disqualified":
                return "badge-disqualified";
            case "Cold Lead: Bogus":
                return "badge-bogus";
            default:
                // return "badge-bogus";
                return "";
        }
    };





    const _loadcontacts = async (page, offset, limit, whereClause = {}, sort = {}) => {
        await dispatch(getContacts({
            page: page,
            model: {
                // payload: {
                id: "",
                data: JSON.stringify({
                    offset: offset,
                    limit: limit,
                    where: whereClause,
                    sort: sort
                })
                // }
            }
        }));
    }


    useEffect(() => {
        if (contactsRequestStatus === fetchStatus.IDLE) {
            dispatch(updateIsFiltered());
            _loadcontacts(0, 0, 10, whereClauseFilter, {});
        }
    }, [contactsRequestStatus]);

    let _dummyCards = [];
    let _contactRecords = new Collection();

    useEffect(() => {
        if (contactsRequestStatus === fetchStatus.LOADING) {
            _dummyCards.push(
                <>
                    <tr>
                        <td colspan="5">
                            <div className='loader-table'>
                                <EllipsisLoader />
                            </div>

                        </td>
                    </tr>
                </>
            );
            // }
            setDummyCards(_dummyCards);
        }
    }, [contactsRequestStatus])


    useEffect(() => {
        if (contactsRequestStatus === fetchStatus.SUCCEEDED || contactsRequestStatus === fetchStatus.DONE) {
            // let _records = _contacts;
            // console.log('_records--->', _records)


            let _records = _contacts.Items;

            if (_contacts.Items && _contacts.Items.length > 0) {
                for (let i = 0; i < _contacts.Items.length; i++) {
                    let item = _contacts.Items[i];
                    console.log('item-----===>', item)
                    console.log('item.key-----===>', item.key)
                    let contactCards = item.records.map((contactData, index) => (
                        <tr key={contactData.id}>
                            <td>{contactData.properties.lastname + ' ' + contactData.properties.firstname}</td>
                            <td>{contactData.properties.email}</td>
                            <td>{contactData.properties.phone}</td>
                            <td>
                                <span className={`badge ${getBadgeClass(contactData.properties.followups)}`}>
                                    {contactData.properties.followups}
                                </span>
                            </td>
                            <td>{contactData.properties.reason}</td>
                        </tr>
                    ));
                    _contactRecords.Add(item.key, contactCards);
                }

                setContactRecords(_contactRecords);
                dispatch(updateStatus());
            }
            dispatch(updateStatus());

        }
    }, [contactsRequestStatus]);

    const tableHeaderRow = <tr>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Phone Number</th>
        <th scope="col">Followups</th>
        <th scope="col">Reason</th>
    </tr>;

    const onLoadItems = async (p) => {
        await _loadcontacts(p, p * 10, 10, whereClauseFilter, {});
    };

    const onPageChanged = async (p, l) => {
        dispatch(updateCurrentPage(p));
        if (l) {
            await onLoadItems(p);
        }
    };

    const onCustomFiterClicked = (e, id, defaultFilter) => {
        e.preventDefault();
        let _whereClauseFilter = whereClauseFilter;
        _whereClauseFilter.followups = id;

        dispatch(updateIsFiltered());
        setWhereClauseFilter(_whereClauseFilter);
        _loadcontacts(0, 0, 10, whereClauseFilter, {});

    };

    const onSearchClicked = async (e, value) => {
        e.preventDefault();
        if (value) {
            //   let where = JSON.parse(JSON.stringify(whereClause));
            //   where.name = value;
            //   await dispatch(updateNameFilter(value));
            //   await dispatch(updateIsFiltered());
            //   _loadApplicants(0, 0, 10, where, sort);

            let _whereClauseFilter = whereClauseFilter;
            _whereClauseFilter.searchTerm = searchTerm;

            dispatch(updateIsFiltered());
            setWhereClauseFilter(_whereClauseFilter);
            _loadcontacts(0, 0, 10, whereClauseFilter, {});
        }
    };

    const onSearchCleared = async (e) => {
        e.preventDefault();
        // await dispatch(updateIsFiltered());
        // let where = JSON.parse(JSON.stringify(whereClause));
        // where.name = "";
        // await dispatch(updateNameFilter(""));
        // _loadApplicants(0, 0, 10, where, sort);

        let _whereClauseFilter = whereClauseFilter;
        _whereClauseFilter.searchTerm = "";

        dispatch(updateIsFiltered());
        setWhereClauseFilter(_whereClauseFilter);
        _loadcontacts(0, 0, 10, whereClauseFilter, {});
    };

    const onFilterClicked = async (e) => {
        e.preventDefault();
        // await dispatch(updateIsFiltered());
        // dispatch(updateFilter({
        //   id: "",
        //   name: "",
        //   whereClause: whereClause,
        //   defaultFilter: false
        // }));
        // _loadApplicants(0, 0, 10, whereClause, sort);
    };


    return (
        <>
            {/* {(usersRequestStatus === fetchStatus.LOADING ||
                processDataByHubSpotUserIdsStatus === fetchStatus.LOADING
            ) && 
            <div className="app-container-loader">
                    <GridLoader />
                </div>} */}

            <div className="filter-bar p-3">
                <>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "", true)}>All</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "FollowUps: Enrich" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "FollowUps: Enrich", true)}>FollowUps: Enrich</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "FollowUps: 1-1 Sales Approach" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "FollowUps: 1-1 Sales Approach", true)}>FollowUps: 1-1 Sales Approach</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "FollowUps: Nurture" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "FollowUps: Nurture", true)}>FollowUps: Nurture</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "FollowUps: Change Communication Channel" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "FollowUps: Change Communication Channel", true)}>FollowUps: Change Communication Channel</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "Cold Lead: Sequence" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "Cold Lead: Sequence", true)}>Cold Lead: Sequence</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "Cold Lead: Disqualified" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "Cold Lead: Disqualified", true)}>Cold Lead: Disqualified</button>
                    <button className={`c-btn mx-1 ${whereClauseFilter.followups === "Cold Lead: Bogus" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "Cold Lead: Bogus", true)}>Cold Lead: Bogus</button>
                </>
            </div>
            <div className="filter-bar p-3 pt-0">
                <div className="col-3">
                    <SearchBar
                        id="txtSearch"
                        name="txtSearch"
                        searchTerm={searchTerm}
                        onSearch={(e) => setSearchTerm(e.target.value)}
                        onClear={(e, value) => onSearchCleared(e)}
                        onSearchClicked={(e, value) => onSearchClicked(e, value)} />
                </div>
            </div>

            <div className='default-container-wrapper p-3'>
                <div className="default-root-container detail-container p-5 mt-2">
                    <PageContainer
                        currentPage={currentPage}
                        totalPageItems={totalPageItems}
                        pageRecords={contactRecords}
                        isItemsLoading={contactsRequestStatus}
                        itemsLoadingContent={dummyCards}
                        onPageChanged={(p, l) => onPageChanged(p, l)}
                        infinite={false}
                        pageRecordsCardClassName={'people-card'}
                        tableHeaderRow={tableHeaderRow}
                    />
                </div>
            </div>
        </>
    )
}

export default Contacts