import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null
    },
};

export const getVocabulary = createAsyncThunk('vocabulary/getVocabulary', async (vocabularyModel, { rejectWithValue }) => {
    // try {
    //     const response = await BajoAPI.post('Process/getVocabulary', userModel, getAxiosRequestConfig());
    //     const data = response.data ? response.data : {};
    //     let users = [];
    //     let totalItems = 0;
    //     if (data.Data && isJSON(data.Data)) {
    //         const parsedData = JSON.parse(data.Data);
    //         users = parsedData.items || [];
    //         totalItems = parsedData.totalItems || 0;
    //     }

    //     return {
    //         users: users,
    //         success: data.Success,
    //         totalItems: totalItems,
    //     };
    // } catch (err) {
    //     return rejectWithValue(err.response.data)
    // }

    try {
        const response = await BajoAPI.post(`Process/getVocabulary`, vocabularyModel, getAxiosRequestConfig());
        console.log("response---> ", response)
        const data = response.data ? response.data.Data : undefined;
        let vocabulary = undefined;
        if (data && isJSON(data)) {
            vocabulary = JSON.parse(data);
        }
        return {
            vocabulary: vocabulary,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const updateVocabulary = createAsyncThunk('vocabulary/updateVocabulary', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Process/updateVocabulary`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : undefined;
        let vocabulary = undefined;
        if (data && isJSON(data)) {
            vocabulary = JSON.parse(data);
        }
        return {
            vocabulary: vocabulary,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const vocabularySlice = createSlice({
    name: 'vocabulary',
    initialState,
    reducers: {
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getVocabulary.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        })
            .addCase(getVocabulary.fulfilled, (state, action) => {
                state.single.data = action.payload.vocabulary;
                // if (action.payload.page >= 0) {
                //     let _records = new Collection();
                //     _records.Add(action.payload.page, action.payload.applicant);
                //     _records.Concat(state.data.firstRecords);
                //     state.data.firstRecords = _records;
                // }
                state.single.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            })
            .addCase(getVocabulary.rejected, (state, action) => {
                state.single.status = fetchStatus.FAILED;
                state.single.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(updateVocabulary.pending, (state, action) => {
                state.modification.status = fetchStatus.LOADING;
            }).addCase(updateVocabulary.fulfilled, (state, action) => {
                state.single.data = action.payload.vocabulary;         // need changes
                state.modification.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(updateVocabulary.rejected, (state, action) => {
                state.modification.status = fetchStatus.FAILED;
                state.modification.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            });
    }
});

export const {
    updateSingleStatus, updateStatus,
} = vocabularySlice.actions;

export default vocabularySlice.reducer

export const selectVocabulary = (state) => {
    return state.vocabulary.single ? state.vocabulary.single.data : undefined;
}
export const selectVocabularyStatus = state => state.vocabulary.single.status;
export const selectVocabularyError = state => state.vocabulary.single.error;

export const selectModificationStatus = state => state.vocabulary.modification.status;
export const selectModificationError = state => state.vocabulary.modification.error;