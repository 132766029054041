import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginError, selectLoginStatus, selectSocialLoginStatus, socialLogin, updateLoginStatus, updateProfileStatus, updateSocialLoginStatus } from '../reducerSlices/accountsSlice';
import { isFormValid } from '../../../component/Controls/services/formService';
import { accountService } from '../services/accountService';
import { fetchStatus, linkedInOptions, redirectHubSpotMarketPlace, tenantId } from '../../../api/client';
import EmailText from '../../../component/Controls/custom/textbox/emailText';
import PasswordText from '../../../component/Controls/custom/textbox/passwordText';
import Logo from '../../../component/logo/logo';
import loginPageImg from '../../../assets/images/login-page.png';

import SocialLoginButton from '../../../component/Controls/custom/buttons/socialLoginButton';

import goolgeLogo from '../../../assets/images/google.png';
import linkedinLogo from '../../../assets/images/linkedin.png';

import { useGoogleLogin } from '@react-oauth/google';
import { LinkedIn, useLinkedIn } from 'react-linkedin-login-oauth2';
import GridLoader from '../../../component/Controls/loaders/gridLoader';
// import { LinkedIn } from 'react-linkedin-login-oauth2';

function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const LOGIN_FORM = "LoginForm";

    const loginStatus = useSelector(selectLoginStatus);
    const socialLoginStatus = useSelector(selectSocialLoginStatus);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [lfSubmitted, setLFSubmitted] = useState(false);


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const onLoginClicked = (e) => {
        e.preventDefault();
        // if (isFormValid(LOGIN_FORM)) {
        if (email !== "" && password !== "") {
            accountService.login({ client: tenantId, email: email, password: password, authenticationType: "TOKEN" });
            // accountService.login({  email: email, password: password });
        }
        else {
            setLFSubmitted(true);
        }
    }

    useEffect(() => {
        if (loginStatus === fetchStatus.SUCCEEDED) {
            if (accountService.isAuthenticated()) {
                dispatch(updateLoginStatus());

                if (accountService.isCompanySetup() === false) {
                    redirectHubSpotMarketPlace();
                } else {
                    navigate("/dashboard");
                }

            }
        }
    }, [loginStatus]);

    useEffect(() => {
        if (loginStatus === fetchStatus.FAILED) {
            alert("Invalid login attempt")
        }
    }, [loginStatus]);


    //Socal Login
    const _getSocialLogin = async (token, provider) => {
        let dataObj = {
            token: token,
            provider: provider,
        }
        await dispatch(socialLogin(dataObj));
    };

    useEffect(() => {
        if (socialLoginStatus === fetchStatus.SUCCEEDED) {
            if (accountService.isAuthenticated()) {
                dispatch(updateSocialLoginStatus());
                dispatch(updateProfileStatus());
                if(accountService.isRegistrationComplete() === false){
                    navigate("/profile-details");
                }
                else if (accountService.isCompanySetup() === false) {
                    redirectHubSpotMarketPlace();
                } else {
                    navigate("/dashboard");
                }
            }
        }
    }, [socialLoginStatus]);

    const signInWithGoogleClicked = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            console.log("codeResponse---->", codeResponse)
            try {
                if (codeResponse && codeResponse.access_token) {
                    console.log("Google Token Response:", codeResponse.access_token);
                    _getSocialLogin(codeResponse.access_token, "google");
                }
            } catch (error) {
                console.error("Error during Google login:", error);
            }
        },
        onError: (error) => console.error("Google Login Failed:", error),
    });

    const redirectUrl = linkedInOptions.REDIRECT_URL;
    const linkedInClientId = linkedInOptions.CLIENT_ID;
    const scope = "openid profile email";

    const { linkedInLogin } = useLinkedIn({
        clientId: linkedInClientId,
        scope: scope,
        redirectUri: redirectUrl,
        onSuccess: (code) => {
            console.log("onSuccess --->", code);

            try {
                if (code) {
                    console.log("LinkedIn Token Response:", code);
                    _getSocialLogin(code, "linkedin");
                }
            } catch (error) {
                console.error("Error during LinkedIn login:", error);
            }

        },
        onError: (error) => {
            console.log("onError--->", error);
        }
    });


    return (
        <>
            {(loginStatus === fetchStatus.LOADING ||
                socialLoginStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <GridLoader />
                </div>}
            <div className="row justify-content-center align-items-center vh-100 m-0">
                <div className="col-4 me-0">
                    <img src={loginPageImg} className="login-page-image" />
                </div>
                <div className="col-6 ms-0">
                    <div className="login-container p-5">
                        <Logo />
                        <br></br>
                        <br></br>
                        <h2>Login</h2>
                        <br></br>
                        <div className="form-group">
                            <label htmlFor="username">Email</label>
                            <EmailText
                                id="txtEmail"
                                name="txtEmail"
                                EmailTextClasses="form-control p-2"
                                placeholder="Email"
                                required={true}
                                errorMessage="Please enter email"
                                form={LOGIN_FORM}
                                submited={lfSubmitted}
                                value={email}
                                onChange={handleEmailChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <PasswordText
                                id="txtPassword"
                                name="txtPassword"
                                PasswordTextClasses="form-control p-2"
                                placeholder="Password"
                                required={true}
                                errorMessage="Please enter password"
                                form={LOGIN_FORM}
                                submited={lfSubmitted}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                        </div>

                        {loginStatus === fetchStatus.LOADING ?
                            <button className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin"></i> Loading...
                            </button>
                            :
                            <button className="btn btn-primary" onClick={(e) => onLoginClicked(e)}>Log In</button>
                        }

                        <hr />

                        <SocialLoginButton
                            text={`Continue With Google`}
                            imgSrc={goolgeLogo}
                            buttonclasses="w-100 mb-3 social-btn-with-img social-btn-light-btn"
                            onClick={signInWithGoogleClicked}
                            loading={socialLoginStatus === fetchStatus.LOADING}
                        />
                        <SocialLoginButton
                            text={`Continue With Linkedin`}
                            imgSrc={linkedinLogo}
                            buttonclasses="w-100 mb-3 social-btn-with-img social-btn-light-btn"
                            onClick={linkedInLogin}
                            loading={socialLoginStatus === fetchStatus.LOADING}
                        />

                        <div className="row mt-5">
                            <div className="col-12  text-center">
                                Need access?  <span className=""><Link to="/register" className="text-pink">Register</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Login