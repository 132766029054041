import { configureStore } from "@reduxjs/toolkit";
import accountsSlice from "./features/accounts/reducerSlices/accountsSlice";
import layoutSlice from "./component/layouts/reducerSlices/layoutSlice";
import userFollowUpProcessSlice from "./features/settings/reducerSlices/userFollowUpProcessSlice";
import dashboardSlice from "./features/dashboard/reducerSlices/dashboardSlice";
import contactsSlice from "./features/contacts/reducerSlices/contactsSlice";
import pageContainerSlice from "./component/Controls/paginators/paginator/reducerSlices/pageContainerSlice";
import vocabularySlice from "./features/settings/reducerSlices/vocabularySlice";


export const store = configureStore({
    reducer: {
        accounts: accountsSlice,
        layout: layoutSlice,
        pageContainer: pageContainerSlice,
        userFollowUpProcess: userFollowUpProcessSlice,
        dashboard: dashboardSlice,
        contacts: contactsSlice,
        vocabulary: vocabularySlice
    }
});

export default store;