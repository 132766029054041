import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getHubSpotAllUsers, processDataByHubSpotUserIds, selectHubSpotAllUsers, selectProcessDataByHubSpotUserIdsStatus, selectStatus, updateStatus } from '../reducerSlices/userFollowUpProcessSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus } from '../../../api/client';
import { accountService } from '../../accounts/services/accountService';
import GridLoader from '../../../component/Controls/loaders/gridLoader';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../component/logo/logo';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';


const UserFollowUp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // if (!pageLoaded) {
        dispatch(updateLayout({
            page: 2,
            title: "Settings"
        }));
        // setPageLoaded(true);
        // }
    }, []);

    const user = accountService.getAuthenticatedUser();
    const _users = useSelector(selectHubSpotAllUsers);
    const usersRequestStatus = useSelector(selectStatus);

    const processDataByHubSpotUserIdsStatus = useSelector(selectProcessDataByHubSpotUserIdsStatus);


    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const _loadUsers = async () => {
        await dispatch(getHubSpotAllUsers({}));
    }

    useEffect(() => {
        if (usersRequestStatus === fetchStatus.IDLE) {
            _loadUsers();
        }
    }, [usersRequestStatus, dispatch]);

    useEffect(() => {
        if (usersRequestStatus === fetchStatus.SUCCEEDED) {
            let _records = _users;
            setUsers(_records);

            const filteredUsers = _users.filter(u => u["allowedHubSpotOwnersId"] === true).map(u => u.id);
            console.log("filteredUsers---->", filteredUsers)
            setSelectedUsers(filteredUsers);
        }
    }, [usersRequestStatus]);

    // useEffect(()=>{
    //     alert( usersRequestStatus)
    // },[usersRequestStatus])

    const handleCheckboxChange = (userId) => {
        setSelectedUsers((prevSelected) =>
            prevSelected.includes(userId)
                ? prevSelected.filter((id) => id !== userId)
                : [...prevSelected, userId]
        );
    };


    const submitSelectedUsers = async () => {
        if (selectedUsers.length === 0) {
            alert('Please select at least one user for processing.');
            return;
        }

        dispatch(processDataByHubSpotUserIds({
            UserIds: selectedUsers,
        }));
    };

    useEffect(() => {
        if (processDataByHubSpotUserIdsStatus === fetchStatus.SUCCEEDED) {
            // navigate('/thank-you')
            alert("Successfully updated")
        }
    }, [processDataByHubSpotUserIdsStatus]);

    const onDashboardClicked = () => {
        navigate("/dashboard");
    }


    return (
        <>
            {(usersRequestStatus === fetchStatus.LOADING ||
                processDataByHubSpotUserIdsStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <GridLoader />
                </div>}

            <div className="row justify-content-between mb-4">
                <div className="col-md-6">
                    <span className="detail-container-title">User Filter For Get Followups Process</span>
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-primary"
                        onClick={submitSelectedUsers}
                        disabled={selectedUsers.length === 0}
                    >
                        Save
                    </button>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Select</th>
                            <th scope="col">Owner ID</th>
                            <th scope="col">User ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onChange={() => handleCheckboxChange(user.id)}
                                        checked={selectedUsers.includes(user.id)}
                                    />
                                </td>
                                <td>{user.id}</td>
                                <td>{user.userId}</td>
                                <td>{user.firstName} {user.lastName}</td>
                                <td>{user.email}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* <div className="row">
                <div className="col-auto">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={submitSelectedUsers}
                        disabled={selectedUsers.length === 0}
                    >
                        Users For Filter
                    </button>
                </div>
            </div> */}
        </>
    );
};

export default UserFollowUp;