import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLayoutDetail } from '../layouts/reducerSlices/layoutSlice';
import logoImg from './../../assets/logo/logo.png';


function SideNavBar() {
    const navigate = useNavigate();
    const [selectedLink, setSelectedLink] = useState(0);

    const layoutDetail = useSelector(selectLayoutDetail);

    useEffect(() => {
        if (layoutDetail) {
            setSelectedLink(layoutDetail.page);
        }
    });

    const onClickDashboard = (e) => {
        navigate('/dashboard');
    }
    
    const onClickSetting = () => {
        navigate('/settings');
    }

    const onContacts = () => {
        navigate('/contacts');
    }

    return (
        <div
            className="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column overflow-auto d-block"
            id="sidebar">
            <ul className="nav flex-column text-white w-100">
                <li href="#" className="nav-link">
                    <div className="nav-item-box">
                        <img src={logoImg} alt="logo" className='side-navbar-logo'></img>
                    </div>
                </li>

                <li href="#" className="nav-link mt-3">
                    <div className="nav-item-box" onClick={onClickDashboard}>
                        <div className={`nav-item-box-img ${selectedLink === 1 ? 'active' : ''}`}>
                            <span><i className={`fa fa-xl fa-dashboard ${selectedLink === 1 ? 'text-white' : ''}`} aria-hidden="true"></i></span>
                        </div>
                        <div className={`nav-item-box-text ${selectedLink === 1 ? 'active' : ''}`}>Dash <br></br>Board</div>
                    </div>
                </li>

                <li href="#" className="nav-link mt-3">
                    <div className="nav-item-box" onClick={onContacts}>
                        <div className={`nav-item-box-img ${selectedLink === 3 ? 'active' : ''}`}>
                            <span><i className={`fa fa-xl fa-address-book ${selectedLink === 3 ? 'text-white' : ''}`} aria-hidden="true"></i></span>
                        </div>
                        <div className={`nav-item-box-text ${selectedLink === 3 ? 'active' : ''}`}>Contacts</div>
                    </div>
                </li>

                {/* <li href="#" className="nav-link">
                    <div className="nav-item-box" onClick={onClickPeople}>
                        <div className={`nav-item-box-img ${selectedLink === 1 ? 'active' : ''}`}>
                            <img src={selectedLink === 1 ? peopleTransparentImg : peopleImg} ></img>
                        </div>
                        <div className={`nav-item-box-text ${selectedLink === 1 ? 'active' : ''}`}>People</div>
                    </div>
                </li> */}

                <span href="#" className="nav-link w-100">
                    <div className="nav-item-box" onClick={onClickSetting}>
                        <div className={`nav-item-box-img ${selectedLink === 2 ? 'active' : 'bg-transparent'}`}>
                            {/* <img src={selectedLink === 9 ? settingTransparentImg : settingImg} ></img> */}
                            <span><i className={`fa fa-xl fa-cog ${selectedLink === 2 ? 'text-white' : ''}`} aria-hidden="true"></i></span>
                        </div>
                        <div className="nav-item-box-text">Settings</div>
                    </div>
                </span>


            </ul>

        </div>
    )
}

export default SideNavBar