import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateLayout } from '../../../../component/layouts/reducerSlices/layoutSlice';
import { getVocabulary, selectModificationStatus, selectVocabulary, selectVocabularyStatus, updateVocabulary } from '../../reducerSlices/vocabularySlice';
import { fetchStatus } from '../../../../api/client';
import GridLoader from '../../../../component/Controls/loaders/gridLoader';


function Vocabulary() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLayout({
      page: 2,
      title: "Settings"
    }));
  }, []);


  // const [properties, setProperties] = useState(
  //   {
  //     amplemarket__email_bounced: {
  //       internalName: "amplemarket__email_bounced",
  //       propertyLabel: "Amplemarket Email Bounced",
  //       fieldType: "text"
  //     },
  //     amplemarket__label: {
  //       internalName: "amplemarket__label",
  //       propertyLabel: "Amplemarket Label",
  //       fieldType: "text"
  //     }
  //   }
  // );

  const [properties, setProperties] = useState({});

  const vocabularyRequestStatus = useSelector(selectVocabularyStatus);
  const vocabulary = useSelector(selectVocabulary);
  const vocabularyModificationStatus = useSelector(selectModificationStatus);

  const _loadVocabulary = async () => {
    await dispatch(getVocabulary({}));
  }

  useEffect(() => {
    if (vocabularyRequestStatus === fetchStatus.IDLE) {
      _loadVocabulary();
    }
  }, [vocabularyRequestStatus]);

  useEffect(() => {
    if (vocabularyRequestStatus === fetchStatus.SUCCEEDED || vocabularyModificationStatus === fetchStatus.SUCCEEDED) {
      let itemsVocabulary = vocabulary["itemsVocabulary"];
      setProperties(itemsVocabulary);
    }
  }, [vocabularyRequestStatus, vocabularyModificationStatus]);

  const onSaveClicked = (e, id) => {
    e.preventDefault();

    let _vocabulary = JSON.parse(JSON.stringify(vocabulary));
    _vocabulary["itemsVocabulary"] = properties;

    console.log("_vocabulary--->", _vocabulary)
    dispatch(updateVocabulary({
      id: id,
      data: JSON.stringify({
        vocabulary: JSON.stringify(_vocabulary)
      })
    }));
  }

  // Update handler for property changes
  const handlePropertyChange = (key, subKey, value) => {
    setProperties(prevProperties => ({
      ...prevProperties,
      [key]: {
        ...prevProperties[key],
        [subKey]: value
      }
    }));
  };


  return (
    <>
      {(vocabularyRequestStatus === fetchStatus.LOADING ||
        vocabularyModificationStatus === fetchStatus.LOADING
      ) && <div className="app-container-loader">
          <GridLoader />
        </div>}

      <div className="row justify-content-between mb-4">
        <div className="col-md-6">
          <span className="detail-container-title">Vocabulary</span>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary"
            onClick={(e) => onSaveClicked(e, vocabulary ? vocabulary.id : "")}
            disabled={!vocabulary || !vocabulary.id}
          >Save
          </button>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-hover">
          <thead className="thead-light">
            <tr>
              <th scope="col">Internal Name</th>
              <th scope="col">Property Label</th>
              <th scope="col">Field Type</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(properties).map((key) => {
              const property = properties[key];
              return (
                <tr key={key} className="align-middle">
                  <td>
                    <label htmlFor={key}>{property.internalName}</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={property.propertyLabel}
                      onChange={(e) => handlePropertyChange(key, "propertyLabel", e.target.value)}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      value={property.fieldType}
                      onChange={(e) => handlePropertyChange(key, "fieldType", e.target.value)}
                    >
                      <option value="text">Text</option>
                      <option value="boolean">Boolean</option>
                      {/* <option value="number">Number</option> */}
                    </select>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Vocabulary;
