import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import Vocabulary from './components/Vocabulary';
import UserFollowUp from './UserFollowUp';

const Setting = () => {
  const dispatch = useDispatch();
  const [pageLoaded, setPageLoaded] = useState(false);



  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 2,
        title: "Settings"
      }));
      setPageLoaded(true);
    }
  });

  const [selectedLink, setSelectedLink] = useState(2);

  const onClickUserFilter = (e) => {
    e.preventDefault();
    setSelectedLink(1);
  }

  const onClickVocabulary = (e) => {
    e.preventDefault();
    setSelectedLink(2);
  }

  return (
    <>
      <div className="px-3 pt-3">
        <div className="row">
          <div className="col-md-2">
            <div className="card setting-card">
              <p className="setting-card-menu-title">Settings</p>
              <div className="setting-card-menu">
                <a href="#" className={`${selectedLink === 1 ? 'active' : ''}`} onClick={(e) => onClickUserFilter(e)}><i className="fa fa-handshake pe-2" />User Filter</a>
                <a href="#" className={`${selectedLink === 2 ? 'active' : ''}`} onClick={(e) => onClickVocabulary(e)}><i className="fa fa-users pe-2" />Vocabulary</a>
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div className="default-root-container detail-container p-4">
              <div className="col-md-12">
                {selectedLink === 1 && <UserFollowUp />}
                {selectedLink === 2 && <Vocabulary />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Setting;